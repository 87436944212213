import React from 'react'

import { SEO } from '@components/SEO'
import { ContentfulAboutImage } from 'graphqlTypes'
import { Text, Heading, Box, Flex, Divider, Container } from 'theme-ui'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { ModalRoutingContext, Link } from 'gatsby-plugin-modal-routing'
import { Layout } from '@components/Layout'
import CloseIcon from '../assets/close.svg'

const InfoBox = ({ children }) => {
  return (
    <Box
      sx={{
        p: [2],
      }}
    >
      {children}
    </Box>
  )
}

const ModalBodyComponent = ({
  aboutImage,
  closeTo,
}: {
  aboutImage: ContentfulAboutImage
  closeTo?: string
}) => (
  <Box
    sx={{
      overflow: 'scroll',
      maxHeight: ['90vh'],
      position: `fixed`,
      top: ['30%'],
      left: ['45%', '25%'],
      mt: '-150px',
      ml: '-150px',
      bg: 'white',
      p: [3],
    }}
  >
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
      }}
    >
      <SideInfo aboutImage={aboutImage} />

      <Flex
        sx={{
          ml: [3],
          flexDirection: ['column'],
          maxWidth: ['50vw'],
        }}
      >
        <Flex
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Heading>
            {aboutImage.name} - {aboutImage.title} -{' '}
            {aboutImage.group === 'Participants'
              ? 'Collaborator'
              : aboutImage.group}
          </Heading>
          <CloseModalIcon closeTo={closeTo} />
        </Flex>
        <Divider />
        <Text variant="p">{aboutImage?.bio?.bio}</Text>
      </Flex>
    </Flex>
  </Box>
)

const CloseModalIcon = ({ closeTo }) => {
  return !!closeTo ? (
    <Link
      to={closeTo}
      style={{
        textDecoration: 'none',
      }}
    >
      <CloseIcon />
    </Link>
  ) : (
    <></>
  )
}

const SideInfo = ({ aboutImage }: { aboutImage: ContentfulAboutImage }) => {
  return (
    <Box
      sx={{
        maxWidth: aboutImage.headshotImage?.fixed?.width,
      }}
    >
      <Img
        fixed={aboutImage?.headshotImage?.fixed || {}}
        key={aboutImage?.headshotImage?.fixed?.src}
        alt={`Headshot of ${aboutImage.name}`}
      />
      <InfoBox>
        <Text>Contact: {aboutImage.email}</Text>
      </InfoBox>
      <InfoBox>
        <Text>
          Affiliations:{' '}
          {aboutImage.affiliations?.map(a => {
            return <Text>{a}</Text>
          })}
        </Text>
      </InfoBox>
      <InfoBox>
        <Text
          sx={{
            mb: [2, 0],
          }}
        >
          Keywords:
          {aboutImage.keywords?.map(word => (
            <Box>
              <Text>{word}</Text>
            </Box>
          ))}
        </Text>
      </InfoBox>
    </Box>
  )
}

const BodyComponent = ({
  aboutImage,
  closeTo,
}: {
  aboutImage: ContentfulAboutImage
  closeTo?: string
}) => (
  <Box
    sx={{
      p: [3],
    }}
  >
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
      }}
    >
      <SideInfo aboutImage={aboutImage} />

      <Flex
        sx={{
          ml: [3],
          flexDirection: ['column'],
          maxWidth: ['50vw'],
        }}
      >
        <Flex
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Heading>
            {aboutImage.name} - {aboutImage.title} - {aboutImage.group}
          </Heading>
          <CloseModalIcon closeTo={closeTo} />
        </Flex>
        <Divider />
        <Text variant="p">{aboutImage?.bio?.bio}</Text>
      </Flex>
    </Flex>
  </Box>
)

const MainPage = ({ aboutImage }: { aboutImage: ContentfulAboutImage }) => (
  <Layout page="i ndividual-about-modal">
    <Container px={6} mb={5}>
      <BodyComponent aboutImage={aboutImage} />
    </Container>
  </Layout>
)

const AboutModal = ({
  data: { aboutImage },
}: {
  data: { aboutImage: ContentfulAboutImage }
}) => (
  <>
    <SEO title={`MIH - ${aboutImage.name}`} />

    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }: { modal: boolean; closeTo: string }) => (
        <Box>
          {modal ? (
            <ModalBodyComponent aboutImage={aboutImage} closeTo={closeTo} />
          ) : (
            <MainPage aboutImage={aboutImage} />
          )}
        </Box>
      )}
    </ModalRoutingContext.Consumer>
  </>
)

export default AboutModal

export const query = graphql`
  query AboutPageSlugsContentful($slug: String!) {
    aboutImage: contentfulAboutImage(slug: { eq: $slug }) {
      headshotImage {
        fixed(height: 300, width: 300) {
          ...GatsbyContentfulFixed
        }
      }
      affiliations
      bio {
        bio
      }
      email
      group
      keywords
      name
      title
      slug
    }
  }
`
